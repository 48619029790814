import React from 'react';
import './index2.css';
import img8 from "./Images/logggo.png";
import img30 from "./Images/logu.png";
import { Link } from 'react-router-dom/cjs/react-router-dom';


 export default function Advisory(){
  
       
    return (
        
        
        <div>
      
        <div>
          <div className='tppor'>
          <img src={img30} className='imo'/>
          
          </div>
        
        
    <div className='hd'>
    Advisory
      </div> 
      <div className='sd'>
      Unlocking the Power of On-Field Agri Advisory
        </div> 
       <div className='tx'>
       <p>
       At Kisan Canteen, we are dedicated to offering comprehensive support to farmers that goes beyond just providing products. We understand that knowledge is power, and when combined with advanced technology, it can revolutionize farming practices. That's why we are proud to introduce our On-Field Agri Advisory services.
       </p>
       <p>
       Harnessing the Power of Advanced AI and ML Technology, Our team of agronomists is equipped with the most advanced AI and ML-based technology to provide farmers with cutting-edge guidance. This powerful combination allows us to offer on-field agri advisory services that are second to none.
       </p>
       <p>
        <div className='bulhd'>Why On-Field Agri Advisory Matters:</div>
        <ul>
          <li><u>Optimized Farming Practices-</u> Our agronomists, armed with AI and ML insights, can help you optimize your farming practices. From crop selection to planting techniques and pest management, they provide recommendations tailored to your specific needs.</li>
          <li><u>Precision Agriculture-</u> We believe that precision is key to higher yields. Our advisory services enable you to implement precision agriculture techniques, resulting in better resource utilization and increased productivity.</li>
          <li><u>Timely Decision-Making-</u> In farming, timing is crucial. Our advisors can provide real-time insights, helping you make informed decisions promptly. This can be the difference between a successful harvest and potential losses.</li>
          <li><u>Increased Yield-</u>  We firmly believe that "one good idea is better than everything." Our agronomists strive to provide you with those valuable ideas and strategies that can significantly boost your crop yields.</li>
          
        </ul>
       </p>
       <p>
       <div className='bulhd'>Join Us for a Prosperous Harvest</div>
       By choosing Kisan Canteen for On-Field Agri Advisory, you are not just receiving advice; you are gaining a competitive edge in modern farming. We are here to empower you with knowledge and technology, ensuring that you achieve higher yields and greater success in your farming endeavors.
       </p>
       <p>
       Together, we can turn your fields into thriving, productive landscapes. Join us in embracing the future of agriculture, where every idea counts and every harvest is bountiful. Your journey to a prosperous harvest begins here with us.
       </p>
       </div>
      
        
    </div>
    <div className='footr'>
          <div className='qr'>
            <img src={img8} width={300}/>
          </div>
          <div className='more'>
             <div className='link'>
                  More Links
             </div>
             <div className='links'>
             <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><a href='./'>Who We Are</a></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'>Research And Development</div>
              <div className='ptr'>Career</div>
              
              
    
             </div>
          </div>
          <div className='cont'>
          <div className='link'>
                  Contact Us
             </div>
             <div className='ptr'>Contact@rabltoajagri.in</div>
          </div>
        </div>
    </div>   
    );
  }