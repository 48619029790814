import React from 'react';
import img8 from "./Images/night.png";
import img30 from "./Images/logu.png";

import { Link } from 'react-router-dom/cjs/react-router-dom';


 export default function Grocery(){
  
       
    return (
        
        
        <div>
      
        <div>
          <div className='tppor'>
          <img src={img30} className='imo'/>
          
          </div>
        
        
    <div className='hd'>
    Grocery
      </div> 
      <div className='container'>
      <div className='sd'>
      Your One-Stop Shop for Affordable Groceries and Electronics in Rural India!
        </div> 
       <div className='tx'>
       <p>
       We understand the challenges faced by our fellow Indians living in the countryside, and we are here to make a difference.
       </p>
       <p>
       At Kisan Canteen, we are on a mission to revolutionize the way rural India shops for groceries and basic electronics. We believe that everyone, regardless of their location, should have access to quality products at affordable prices. With this commitment in mind, we have embarked on a journey to bridge the gap between urban and rural markets.
       </p>
       <p>
       Our vision is simple yet impactful – to bring convenience, affordability, and quality to the doorsteps of farmers and residents in the countryside. We aim to transform the shopping experience for rural India, making it easier, more accessible, and budget-friendly.
       </p>
       <p>
        <div className='bulhd'>What We Offer</div>
        <ul>
          <li><u>Groceries Galore-</u> We offer a wide range of daily grocery items, from fresh produce to pantry essentials. Say goodbye to those long and tiring trips to the city. With us, you can shop from the comfort of your home and have your groceries delivered right to your doorstep.</li>
          <li><u>Discounted Prices-</u> We understand the importance of affordability, especially in rural areas. That's why we offer competitive prices and regular discounts on our products. Save more on your everyday needs with us.</li>
          <li><u>Convenient Delivery-</u> Our efficient delivery system ensures that your orders reach you in a timely manner. No more waiting for weeks to receive your purchases. We prioritize your convenience.</li>
          <li><u>Quality Assurance-</u> We take quality seriously. Rest assured that all our products meet stringent quality standards, so you get nothing but the best.   </li>
          
        </ul>
       </p>
       <p>
       <div className='bulhd'>Join Us in Empowering Rural India</div>
By choosing Kisan Canteen, you are not just shopping; you are becoming a part of a movement to empower rural India. Together, we can make a difference and bring prosperity to our countryside.
       </p>
       <p>
       Join us today and experience a new era of shopping where convenience, affordability, and quality converge. Let's build a stronger and more prosperous rural India, one order at a time.
       </p>
       </div>
       </div>
        
    </div>
    <div className='footr'>
          <div className='qr'>
            <img src={img8} width={300}/>
          </div>
          <div className='more'>
             <div className='link'>
                  More Links
             </div>
             <div className='links'>
              <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><a href='./'>Who We Are</a></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'>Research And Development</div>
              <div className='ptr'>Career</div>
              
              
              
    
             </div>
          </div>
          <div className='cont'>
          <div className='link'>
                  Contact Us
             </div>
             <div className='ptr'>Contact@rabltoajagri.in</div>
          </div>
        </div>
    </div>   
      
    );
  }
 
  