import React from 'react';
import './index2.css';
import img8 from "./Images/logggo.png";
import img30 from "./Images/logu.png";
import img4 from './Images/canteen desk.jpg';
import img1 from './Images/newz.jpg';
import img2 from './Images/blog.jpg';
import img3 from './Images/land.jpg';
import img5 from './Images/11.jpg';
import img6 from './Images/12.jpg';
import img7 from './Images/13.jpg';
import { Link } from 'react-router-dom/cjs/react-router-dom';
export default function About(){

  
    return (
      <div>
      
      <div>
        <div className='tppor'>
        <img src={img30} className='imo'/>
        
        </div>
      
      
  <div className='hd'>
  Our Future
    </div> 
    <div className='container'>
    <div className='sd'>
    Embracing the Vision of Community First
      </div> 
     <div className='tx'>
     <p>
     At RABLTAOJ we stand by a powerful principle: 'Build the community before the product.' Guided by this philosophy, our CEO, Kunal Anand, has steered our endeavors towards fostering strong bonds within communities. Today, we're excited to announce a significant step toward realizing our vision by expanding our Kisan canteen chain across the nation.
     </p>
     <p>
     Our commitment extends beyond just providing all necessities ; it's about nurturing a sense of belonging and togetherness that goes beyond any relation. As we embark on this journey to elevate community connections, our dedicated Research and Development team is tirelessly collaborating with esteemed organizations to bring forth products that resonate with the core values of each community.
     </p>
     <p>
     There is tremendous scope of technology-enabled disruption and development in this sector. DeHaat Technology Solution has been designed to create a transparent marketplace to provide direct market access to farmers and a reliable supply chain for large institutional buyers. With our "last mile connection" with the farmers and their farms, we continue direct on-field monitoring over quantity and quality over the produce growth cycle and provide full logistical support for harvesting and transportation, storage at conveniently located DeHaat Collection Centers - thus eliminating distress selling and the meddling middlemen that has been affecting Farmers in India for generations. This has resulted an increment of up to 20% gate prices for the farmer.
     </p>
     </div>
     </div>
      
  </div>
  <div className='footr'>
        <div className='qr'>
        <img src={img8} width={300}/>
        </div>
        <div className='more'>
           <div className='link'>
                More Links
           </div>
           <div className='links'>
           <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><Link to='./Who'>Who We Are</Link></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'><Link to='./About'>Research And Developmentz</Link></div>
              <div className='ptr'>Career</div>
            
            
            
  
           </div>
        </div>
        <div className='cont'>
        <div className='link'>
                Contact Us
           </div>
           <div className='ptr'>Contact@rabltoajagri.in</div>
        </div>
      </div>
  </div>   
        
        
    
      );
}
