import React from 'react';
import img8 from "./Images/night.png";
import img30 from "./Images/logu.png";

import { Link } from 'react-router-dom/cjs/react-router-dom';


 export default function Impact(){
  
       
    return (
        
        
        <div>
      
        <div>
          <div className='tppor'>
          <img src={img30} className='imo'/>
          
          </div>
        
        
    <div className='hd'>
    Our Impact
      </div> 
      <div className='container'>
      <div className='sd'>
      Towards the positively altering lives and landscapes
        </div> 
       <div className='tx'>
       <p>
       Kisan Canteen is making a significant and diverse impact across multiple sectors in India. Economically, it's generating employment opportunities and fostering entrepreneurship by offering franchise partnerships. In the agricultural sector, the company provides essential support with discounts on farming supplies, advanced tech solutions for improved farming practices, and direct market access for farmers. Socially, Kisan Canteen is dedicated to supporting menstrual health, encouraging community participation, and sponsoring local sports and games. Consumers benefit from substantial discounts on groceries and electronics, while access to agricultural finance and insurance ensures financial security. 
       </p>
       <p>
       Environmentally, the "One Farmer, One Plant" initiative promotes afforestation, cleaner air, and climate change mitigation, alongside soil conservation, biodiversity enhancement, and carbon sequestration. Overall, we contributes to community development, improving access to essential services in rural areas. With nodes established in every block of India, Kisan canteen's impact is both extensive and transformative..
       </p>
       
       <p>
        <div className='bulhd'>Our Vision</div>
        <ul>
          <li><u>Economic Empowerment-</u>  Creates employment opportunities and nurtures entrepreneurship through franchise partnerships.</li>
          <li><u>Agricultural Transformation-</u>Provides cost-saving discounts on essential farming supplies and deploys advanced tech solutions for enhanced crop production.</li>
          <li><u>Consumer Benefits-</u> Offers substantial discounts on groceries and basic electronics, making daily essentials more affordable.</li>
          <li><u>Social Initiatives-</u> Promotes menstrual health with free pads, fosters community participation through engaging initiatives, and sponsors local sports for a healthier community.</li>
          <li><u>Financial Inclusion-</u> Provides access to agricultural finance and insurance, ensuring financial security for farmers.</li>
          <li><u>Environmental Stewardship-</u> Drives afforestation through "One Farmer, One Plant," contributing to cleaner air and climate change mitigation.</li>
          <li><u>Community Development-</u>  Fosters holistic community development by improving access to essential services and generating employment in rural areas.</li>
          <li><u>National Reach-</u>  Establishes nodes in every block of India, ensuring a far-reaching impact across the country.</li>
          <li><u>Direct Market Access-</u>Connects farmers directly to corporate markets, reducing intermediaries and increasing income stability for farmers.</li>
          
        </ul>
       </p>
       <p>
       <div className='bulhd'>Join Us in Empowering Rural India</div>
By choosing Kisan Canteen, you are not just shopping; you are becoming a part of a movement to empower rural India. Together, we can make a difference and bring prosperity to our countryside.
       </p>
       <p>
       Join us today and experience a new era of shopping where convenience, affordability, and quality converge. Let's build a stronger and more prosperous rural India, one order at a time.
       </p>
       </div>
      
       </div>
    </div>
    <div className='footr'>
          <div className='qr'>
            <img src={img8} width={300}/>
          </div>
          <div className='more'>
             <div className='link'>
                  More Links
             </div>
             <div className='links'>
              <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><a href='./'>Who We Are</a></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'>Research And Development</div>
              <div className='ptr'>Career</div>
              
              
              
    
             </div>
          </div>
          <div className='cont'>
          <div className='link'>
                  Contact Us
             </div>
             <div className='ptr'>Contact@rabltoajagri.in</div>
          </div>
        </div>
    </div>   
      
    );
  }
 
  