import React from 'react';
import './index2.css';
import img8 from "./Images/night.png";
import img30 from "./Images/logu.png";
import { Link } from 'react-router-dom/cjs/react-router-dom';


 export default function Soil(){
  
       
    return (
        
        
        <div>
      
        <div>
          <div className='tppor'>
          <img src={img30} className='imo'/>
          
          </div>
        
        
    <div className='hd'>
    Bridging the Technology Gap in Rural India
      </div> 
      <div className='container'>
      <div className='sd'>
      Harvest & Market Access
        </div> 
       <div className='tx'>
       <p>
       At Kisan Canteen, we believe in going beyond the basics to empower farmers and transform rural India into a technologically advanced landscape. Our commitment extends to eliminating the technology gap between farmers and the countryside.
       </p>
       <p>
       Introducing Instant Crop and Field Nutrition Facilities
We are excited to introduce revolutionary instant facilities that allow you to access critical information about your crops and fields with ease. With a simple click, you can gain insights into your crop nutrition as well as your field's overall health.
       </p>
       <p>
       To bring you these groundbreaking facilities, we have partnered with the most advanced and fastest technology providers. Our collaboration ensures that you have access to real-time data and insights that can significantly enhance your farming practices.
       </p>
       </div>
       </div>
        
    </div>
    <div className='footr'>
          <div className='qr'>
            <img src={img8} width={300}/>
          </div>
          <div className='more'>
             <div className='link'>
                  More Links
             </div>
             <div className='links'>
             <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><Link to='./Who'>Who We Are</Link></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'><Link to='./About'>Research And Developmentz</Link></div>
              <div className='ptr'>Career</div>
              
              
              
    
             </div>
          </div>
          <div className='cont'>
          <div className='link'>
                  Contact Us
             </div>
             <div className='ptr'>Contact@rabltoajagri.in</div>
          </div>
        </div>
    </div>   
    );
  }
 
  