import React from 'react';
import './index2.css';
import img8 from "./Images/logggo.png";
import img30 from "./Images/logu.png";
import { Link } from 'react-router-dom/cjs/react-router-dom';


 export default function Farm(){
  
       
    return (
        
        
        <div>
      
        <div>
          <div className='tppor'>
          <img src={img30} className='imo'/>
          
          </div>
        
        
    <div className='hd'>
    Farm intelligence 

      </div> 
      <div className='sd'>
      Empowering farmers through technology at Kisan Canteen .
        </div> 
       <div className='tx'>
       <p>
       Farm Intelligence is at the heart of RABLTAOJ Agri vision to revolutionize agriculture in India. With a firm commitment to leveraging the latest technology, the company is already in the process of collaborating with industry giants to bring cutting-edge solutions to farmers. One unique aspect is that the facilities of Farm Intelligence will be readily available at Kisan Canteen.
       </p>
       <p>
       What sets kisan canteen apart is its approach: ensuring that farmers' basic needs are met first. By prioritizing the essentials, such as affordable farming supplies and direct market access, the company creates a strong foundation upon which it can seamlessly integrate advanced technology.
       </p>
       <p>
       This strategic approach recognizes that providing farmers with the tools they need to thrive in traditional farming is the initial step towards ushering in a new era of agricultural innovation. By addressing fundamental challenges, Kisan Canteen paves the way for a smoother transition to technologies like AI, machine learning, and data-driven insights. Farm Intelligence, when integrated into this foundation, enhances productivity, optimizes resource usage, and ensures sustainability in farming practices.
       </p>
       <p>
       Ultimately, Kisan Canteen's commitment to Farm Intelligence is not just about adopting the latest tech trends; it's about empowering farmers, improving their livelihoods, and setting the stage for a more prosperous and sustainable future in Indian agriculture. Through this holistic approach, kisan canteen aims to bridge the gap between traditional farming and cutting-edge technology, ensuring that all farmers, regardless of their resources, can benefit from the digital revolution in agriculture.
       </p>
       </div>
      
        
    </div>
    <div className='footr'>
          <div className='qr'>
            <img src={img8} width={300}/>
          </div>
          <div className='more'>
             <div className='link'>
                  More Links
             </div>
             <div className='links'>
             <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><a href='./'>Who We Are</a></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'>Research And Development</div>
              <div className='ptr'>Career</div>
              
              
              
    
             </div>
          </div>
          <div className='cont'>
          <div className='link'>
                  Contact Us
             </div>
             <div className='ptr'>Contact@rabltoajagri.in</div>
          </div>
        </div>
    </div>   
    );
  }