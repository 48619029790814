import React from 'react';
import './index2.css';
import img8 from "./Images/logggo.png";
import img30 from "./Images/logu.png";
import { Link } from 'react-router-dom/cjs/react-router-dom';


 export default function Finance(){
  
       
    return (
        
        
        <div>
      
        <div>
          <div className='tppor'>
          <img src={img30} className='imo'/>
          
          </div>
        
        
    <div className='hd'>
    Empowering Farmers with Agri Finances
      </div> 
      <div className='sd'>
      Harvest & Market Access
        </div> 
       <div className='tx'>
       <p>
       At Kisan Canteen, our commitment to farmers goes beyond providing products and knowledge. We understand the unique financial needs of farmers and the uncertainties they face in their profession. That's why we offer two essential financial solutions tailored to the agricultural community.
       </p>
       <div className='hd'>
       1.Financial Assistance for Farming Practices

      </div> 
       <p>
       We recognize that farming requires substantial investments in essential needs, from seeds and fertilizers to equipment and labor. To support farmers in their journey, we provide financial assistance that covers various aspects of farming practices. Our goal is to ensure that every farmer has access to the necessary resources to optimize their agricultural operations.
       </p>
       <div className='hd'>
       1.Financial Assistance for Farming Practices

      </div> 
       <p>
       The life of a farmer and their crops is often at the mercy of unpredictable factors like weather, pests, and other natural challenges. To mitigate these risks, we offer a range of affordable insurance facilities designed to cater to the specific needs of farmers in different geographical regions. 
       </p>
       </div>
      
        
    </div>
    <div className='footr'>
          <div className='qr'>
            <img src={img8} width={300}/>
          </div>
          <div className='more'>
             <div className='link'>
                  More Links
             </div>
             <div className='links'>
             <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><a href='./'>Who We Are</a></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'>Research And Development</div>
              <div className='ptr'>Career</div>
              
              
              
    
             </div>
          </div>
          <div className='cont'>
          <div className='link'>
                  Contact Us
             </div>
             <div className='ptr'>Contact@rabltoajagri.in</div>
          </div>
        </div>
    </div>   
      
    );
  }
 
  