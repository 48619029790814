import React from 'react';
import './index2.css';
import img8 from "./Images/night.png";
import img30 from "./Images/logu.png";
import { Link } from 'react-router-dom/cjs/react-router-dom';

 export default function Input(){
  
       
    return (
        
        
        <div>
      
        <div>
          <div className='tppor'>
          <img src={img30} className='imo'/>
          
          </div>
        
        
    <div className='hd'>
    Your Complete Solution for Rural Living!
      </div> 
      <div className='container'>
      <div className='sd'>
      Harvest & Market Access
        </div> 
       <div className='tx'>
       <p>
       At Kisan Canteen,we understand that rural India depends on agriculture as its backbone. To further support our commitment to rural prosperity, we are excited to introduce a wide range of cost-effective and high-quality agricultural inputs. We are your one-stop shop for everything you need to boost your farming endeavors.
       </p>
       <p>
       Join Us in Cultivating Prosperity
At Kisan Canteen, we are not just selling products; we are empowering the rural farming community. Our goal is to make modern farming practices and quality inputs accessible to everyone, irrespective of their location.
       </p>
       <p>
       By choosing us as your agricultural input partner, you are taking a significant step towards achieving better crop yields, increased income, and a brighter future for rural India.<br/>
Let's cultivate prosperity together. Shop with us today and experience the difference quality agricultural inputs can make in your farming journey. Your success is our success!
       </p>
       </div>
      </div>
        
    </div>
    <div className='footr'>
          <div className='qr'>
            <img src={img8} width={300}/>
          </div>
          <div className='more'>
             <div className='link'>
                  More Links
             </div>
             <div className='links'>
             <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><Link to='./Who'>Who We Are</Link></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'><Link to='./About'>Research And Developmentz</Link></div>
              <div className='ptr'>Career</div>
              
              
              
    
             </div>
          </div>
          <div className='cont'>
          <div className='link'>
                  Contact Us
             </div>
             <div className='ptr'>Contact@rabltoajagri.in</div>
          </div>
        </div>
    </div>   
      
    );
  }
 
  