import React from 'react';
import './index2.css';
import img8 from "./Images/logggo.png";
import img30 from "./Images/logu.png";
import img4 from './Images/canteen desk.jpg';
import img1 from './Images/newz.jpg';
import img2 from './Images/blog.jpg';
import img3 from './Images/land.jpg';
import img5 from './Images/11.jpg';
import img6 from './Images/12.jpg';
import img7 from './Images/13.jpg';
import { Link } from 'react-router-dom/cjs/react-router-dom';
export default function Who(){

  
    return (
      <div>
      
      <div>
        <div className='tppor'>
        <img src={img30} className='imo'/>
        
        </div>
      
      
  <div className='hd'>
  Who We Are
    </div> 
    <div className='container'>
    <div className='sd'>
    The future Agri Giant.!
      </div> 
     <div className='tx'>
     <p>
     We are the flagship venture of RABLTAOJ Private Limited, a company actively engaged in the lifestyle sector under the brand name Selibrity. Our commitment to serving society extends through our consultancy services offered under the brand RCS.
     </p>
     <p>
     Kisan Canteen is our dedicated agricultural venture, guided by a simple yet powerful motto: 'Making farmers happy.' We achieve this by providing essential necessities to farmers at affordable prices, facilitating their access to technology, and connecting them to markets that offer better prices for their produce.
     </p>
     <p>
     In pursuit of this vision, we are excited to announce the launch of Kisan Canteen outlets across block-level regions throughout India. Our mission is to serve the heart of India, the countryside, and bring about positive change in the lives of our fellow countrymen."
     </p>
     </div>
     </div>
      
  </div>
  <div className='footr'>
        <div className='qr'>
        <img src={img8} width={300}/>
        </div>
        <div className='more'>
           <div className='link'>
                More Links
           </div>
           <div className='links'>
           <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><Link to='./Who'>Who We Are</Link></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'><Link to='./About'>Research And Developmentz</Link></div>
              <div className='ptr'>Career</div>
            
            
            
  
           </div>
        </div>
        <div className='cont'>
        <div className='link'>
                Contact Us
           </div>
           <div className='ptr'>Contact@rabltoajagri.in</div>
        </div>
      </div>
  </div>   
        
        
    
      );
}
