import React from 'react';
import './index2.css';
import img8 from "./Images/logggo.png";
import img30 from "./Images/logu.png";
import { Link } from 'react-router-dom/cjs/react-router-dom';


 export default function Equip(){
  
       
    return (
        
        
        <div>
      
        <div>
          <div className='tppor'>
          <img src={img30} className='imo'/>
          
          </div>
        
        
    <div className='hd'>
    Equipment 
      </div> 
      <div className='container'>
      <div className='sd'>
      Bringing the future of agriculture to their fields
        </div> 
       <div className='tx'>
       <p>
       RABLTAOJ AGRI envisions bringing India parallel to the world in terms of modern agricultural equipment. As India emerges in the drone sector, we are committed to upgrading and advancing this technology. In line with our vision, we are set to launch the first Unmanned Aerial Tractor (UAT), a groundbreaking innovation comparable to air tractors.
       </p>
       <p>
       The UAT boasts numerous advanced features, including laser pesticide application, and offers six times the capacity of current agricultural drones. With double the flight time of existing systems and the ability to cover twice as much area in one flight during spraying operations, the UAT is set to redefine efficiency in agriculture.
       </p>
       <p>
       One of the most remarkable aspects of the UAT is its affordability, ensuring that cutting-edge technology is accessible to farmers. Our company believes that introducing this technology will revolutionize the Indian agricultural industry, providing farmers with a cost-effective and efficient means to manage their crops. Furthermore, our commitment to making all equipment available and affordable underscores our dedication to supporting farmers in embracing modern farming methods for enhanced productivity and sustainability.
       </p>
       </div>
      
       </div>
    </div>
    <div className='footr'>
          <div className='qr'>
            <img src={img8} width={300}/>
          </div>
          <div className='more'>
             <div className='link'>
                  More Links
             </div>
             <div className='links'>
             <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><a href='./'>Who We Are</a></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'>Research And Development</div>
              <div className='ptr'>Career</div>
              
              
              
    
             </div>
          </div>
          <div className='cont'>
          <div className='link'>
                  Contact Us
             </div>
             <div className='ptr'>Contact@rabltoajagri.in</div>
          </div>
        </div>
    </div>   
    );
  }