import React from 'react';
import About from './About';
import Main from './Main';
import Grocery from './Grocery';
import Soil from './Soil';
import Input from './Input';
import Annc from './Annc';
import Finance from './Finance';
import Who from './who';
import { Route,Switch } from 'react-router-dom/cjs/react-router-dom.min';
import Advisory from './Advisory';
import Equip from './Equip';
import Farm from './Farm';
import Trade from './Trade';
import Loan from './Loan';
import Impact from './Impact';
import { Navbar } from './Navbar';






function App(){

  return (
   
 <>
   <Navbar/>
   <Switch>
    <Route exact path='/' component={Main}/>
    <Route exact path='/About' component={About}/>
    <Route exact path='/Grocery' component={Grocery}/>
    <Route exact path='/Soil' component={Soil}/>
    <Route exact path='/Input' component={Input}/>
    <Route exact path='/Annc' component={Annc}/>
    <Route exact path='/Finance' component={Finance}/>
    <Route exact path='/Advisory' component={Advisory}/>
    <Route exact path='/Equip' component={Equip}/>
    <Route exact path='/Farm' component={Farm}/>
    <Route exact path='/Trade' component={Trade}/>
    <Route exact path='/Who' component={Who}/>
    <Route exact path='/Loan' component={Loan}/>
    <Route exact path='/Impact' component={Impact}/>
   </Switch>
   </>
   
   
   
   
   
  );
}

export default App;
