import React from 'react';
import './index2.css';
import img8 from "./Images/logggo.png";
import img30 from "./Images/logu.png";
import img4 from './Images/canteen desk.jpg';
import img1 from './Images/newz.jpg';
import img2 from './Images/blog.jpg';
import img3 from './Images/land.jpg';
import img5 from './Images/11.jpg';
import img6 from './Images/12.jpg';
import img7 from './Images/13.jpg';
export default function Annc(){

  
    return (
      <div>
      
      <div>
        <div className='tppor'>
        <img src={img30} className='imo'/>
       
        </div>
      
      
  <div className='hd'>
  A Spectacular Revelation is On the Horizon!
    </div> 
    <div className='container'>
    <div className='sd'>
    Harvest & Market Access
      </div> 
     <div className='tx'>
     <p>
     As we are close to complete our first phase of expanding Kisan canteen chain across the targeted country side India, get ready for the upcoming Megha Phase of RABLTAOJ Agri ! <br/>
We are thrilled to announce that the real hero of every Indian, Sonu Sood, will be launching this monumental phase with many renowned personalities.
     </p>
     <p>
     We are committed to bringing you a series of significant announcements in the next phase.<br/>
Stay tuned for the big reveal, as we are set to announce the official launch date shortly. In the meantime, tap below to catch a glimpse of Sonu Sood's heartfelt request, which has ignited this exciting journey.
     </p>
     
     </div>
     </div>
      
  </div>
  <div className='footr'>
        <div className='qr'>
        <img src={img8} width={300}/>
        </div>
        <div className='more'>
           <div className='link'>
                More Links
           </div>
           <div className='links'>
            <div className='ptr'>Grocery</div>
            <div className='ptr'>Soil Testing</div>
            <div className='ptr'>Agri Input</div>
            <div className='ptr'>Agri Finance</div>
            <div className='ptr'>Advisory</div>
            <div className='ptr'>Equipments</div>
            <div className='ptr'>Farm Intelligence</div>
            
            
  
           </div>
        </div>
        <div className='navi'>
        <div className='link'>
                Navigation
           </div>
           <div className='links'>
            <div className='ptr'><a href='www.youtube.com'>Who We Are</a></div>
            <div className='ptr'>Being Agripreneur</div>
            <div className='ptr'>Kisan Ki Baat</div>
            <div className='ptr'>Research And Development</div>
            <div className='ptr'>Career</div>
            
            
  
           </div>
        </div>
        <div className='cont'>
        <div className='link'>
                Contact Us
           </div>
           <div className='ptr'>Contact@rabltoajagri.in</div>
        </div>
      </div>
  </div>   
        
        
    
      );
}
