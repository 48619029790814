import React from 'react';
import About from './About';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import bootstrap from 'bootstrap';
import './index.css';

import {Navbar} from "./Navbar"


import img4 from './Images/canteen desk.jpg';
import img1 from './Images/newz.jpg';
import img2 from './Images/blog.jpg';
import img3 from './Images/land.jpg';
import img5 from './Images/11.jpg';
import img6 from './Images/12.jpg';
import img7 from './Images/13.jpg';
import img8 from './Images/partner.jpg';
import img9 from './Images/kunal.jpg';
import img10 from './Images/mix.jpg';
import img11 from './Images/trees.jpg';
import img12 from './Images/farmer.jpg';
import img13 from './Images/sood.jpg';
import img14 from './Images/agm.jpg';
import img15 from './Images/SMP.jpg';
import img16 from './Images/3p.jpg';
import img17 from './Images/1p.jpg';
import img18 from './Images/2p.jpg';
import img19 from './Images/india.jpg';
import img20 from './Images/farm.jpg';
import img21 from './Images/trie.jpg';
import img22 from './Images/night.png';
import img23 from './Images/dw.png';
import img24 from './Images/canteenmob(2).jpg'
import img25 from './Images/career.jpg'
export default function Main(){

  return (
    <>
   <div>
    
    
    <div id="carouselExampleCaptions" class="carousel slide wide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img5} class="img-fluid ll"/>
      <div className='bottom-lefttt'><div className='sml'>#Solution In Our Hands</div>PROVIDING BASIC<br/>NEEDS</div>
    </div>
    
    <div class="carousel-item">
      <img src={img6} class="img-fluid" alt="..."/>
      <div className='bottom-lefttt'><div className='sml'>#Solution In Our Hands</div>CONNECTING THEM <br/>TO THE MARKET</div>
    </div>
    <div class="carousel-item">
      <img src={img7} class="img-fluid" alt="..."/>
      <div className='bottom-lefttt'><div className='sml'>#Solution In Our Hands</div>BRIDGING THE GAP <br/>BETWEEN FARMERS<br/>AND TECHNOLOGY</div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div id="carouselExampleCaptions5" class="carousel slide narrow" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions5" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions5" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions5" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img16} class="imgra ll"/>
      <div className='bottom-lefttt'>#Solution In Our Hands<br/><br/>PROVIDING BASIC<br/>NEEDS</div>
    </div>
    
    <div class="carousel-item">
      <img src={img17} class="imgra ll" alt="..."/>
      <div className='bottom-lefttt'>#Solution In Our Hands<br/><br/>BRIDGING THE GAP <br/>BETWEEN FARMERS<br/>AND TECHNOLOGY</div>
      
    </div>
    <div class="carousel-item">
      <img src={img18} class="imgra ll" alt="..."/>
     < div className='bottom-lefttt'>#Solution In Our Hands<br/><br/>CONNECTING THEM <br/>TO THE MARKET</div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions5" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions5" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    <div className='lonks'>
      <Link className='lonk ol1' to='/Grocery'>Grocery</Link>
      <Link className='lonk ol2' to='/Soil'>Soil Testing</Link>
      <Link className='lonk ol3' to='/Input'>Agri Input</Link>
      <Link className='lonk ol1' to='/Finance'>Agri Finance</Link>
      <Link className='lonk ol2' to='/Advisory'>Advisory</Link>
      <Link className='lonk ol3' to='/Equip'>Equipments</Link>
      <Link className='lonk a1' to='/Farm'>Farm Intelligence</Link>
      <Link className='lonk a2' to='/Trade'>Agri Trade</Link>
      

    </div>
   
    <div className="App xd" data-aos="slide-up">
      
      <div className='ad container grow'>
      
              
              <img src={img10} className='zz'/>
              <div className='content'>
              <div className='bottom-leftt'>#Tomorrow's Agri Giant</div>
              <Link to='/About' className='bottom-left'>OUR FUTURE<span>&#129141;</span></Link>
              </div>
              
      </div>
      <div className='bd container grow'>
      
              
              <img src={img13} className='zz'/>
              <div className='content'>
              <div className='bottom-leftt'>Complete Umbrella Is Yet To Come</div>
              <Link to='/Annc' className='bottom-left'>ANNOUNCEMENT<span>&#129141;</span></Link>
              </div>
              
              </div>
      <div className='cd container grow'>
      
              
              <img src={img9} className='zz'/>
              <div className='content'>
              <div className='bottom-leftt'>#We Make Farmers Happy</div>
              <Link to='/Impact' className='bottom-left'>OUR IMPACT<span>&#129141;</span></Link>
              </div>
              
      </div>   
  </div>
  </div>
  <div id='carouselExampleCaptions2' className="carousel slide rf wide" data-aos='fade' data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
    <div class='wrappers'>
     <div class='divimage'><img src={img3} class="img-fluid"/></div>
     <div class='divtext'>5000 Blocks Covered By 2030</div>
</div>
      
      
    </div>
    
    <div class="carousel-item">
    <div class='wrappers'>
     <div class='divimage'><img src={img12} class="img-fluid" alt="..."/></div>
     <div class='divtext'>5Mn Farmers covered by 2030</div>
</div>
      
      
    </div>
    <div class="carousel-item">
    <div class='wrappers'>
     <div class='divimage'><img src={img11} class="img-fluid" alt="..."/></div>
     <div class='divtext'>One Million Trees Will Be Planted By 2027</div>
</div>
      
      
      </div>
    
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div id='carouselExampleCaptions6' className="carousel slide rf narrow" data-aos='fade' data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions6" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions6" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions6" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
    
     <img src={img19} class="img-fluid"/>
     <div class='bottom-lefto'>5000 Blocks Covered By 2030</div>

    </div>
    
    <div class="carousel-item">
    
     <img src={img20} class="img-fluid" alt="..."/>
     <div class='bottom-lefto'>5Mn Farmers covered by 2030</div>

    </div>
    <div class="carousel-item">
    
     <img src={img21} class="img-fluid" alt="..."/>
     <div class='bottom-lefto'>One Million Trees Will Be Planted By 2027</div>

      </div>
    
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions6" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions6" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div id='carouselExampleCaptions3' className="carousel slide rf" data-aos='fade' data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions3" data-bs-slide-to="1" aria-label="Slide 2"></button>
    
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
    <div className="App xd" data-aos="slide-up">
      
      <div className='ad container grow'>
      
              
              <img src={img25} className='zz'/>
              <div className='content'>
              <div className='bottom-leftt'>#Be part of the next agri revolution</div>
              <div class="bottom-left">Carrer<span>&#129141;</span></div>
              </div>
      </div>
      <div className='bd container grow'>
      
              
              <img src={img2} className='zz'/>
              <div className='content'>
              <div className='bottom-leftt'>#Story Of The Real India</div>
              <div class="bottom-left">Kisan Ki BaAt<span>&#129141;</span></div>
              </div>
      </div>
      <div className='cd container grow'>
      
              
              <img src={img1} className='zz'/>
              <div className='content'>
              <div className='bottom-leftt'>Headline</div>
              <div class="bottom-left">In The News<span>&#129141;</span></div>
              </div>
      </div>   
  </div>
      
    </div>
    
    <div className='carousel-item'>
    <div className="App xd" data-aos="slide-up">
      
      <div className='ad container grow'>
      
              
              <img src={img14} className='zz'/>
              <div className='content'>
              <div className='bottom-leftt'>Headline</div>
              <div class="bottom-left">Press Release<span>&#129141;</span></div>
              </div>
      </div>
      <div className='bd container grow'>
      
              
              <img src={img15} className='zz'/>
              <div className='content'>
              <div className='bottom-leftt'>#Connect To The Real India</div>
              <div class="bottom-left">Social Media<span>&#129141;</span></div>
              </div>
      </div>
      <div className='cd container grow'>
      
              
              <img src={img8} className='zz dr'/>
              <div className='content'>
              <div className='bottom-leftt'>Baluster Of Our Milestone</div>
              <div class="bottom-left ">Our Partner<span>&#129141;</span></div>
              </div>
      </div>   
  </div>
      
    </div>
    
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions3" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions3" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
  
  <div className='wide'> 
    <img src={img4} className='ql'/>
    </div> 
    <div className='narrow'>
    <img src={img24} className='ql'/>
    </div>
    <div className='lanks'>
             <div className='lonk'>Being Agripreneur</div>
             <Link className='lonk' to='/Loan'>Loan Upto 25 Lakhs</Link>
             <div className='lonk'>Agriprenuer Benifits</div>
        </div>
        
        <footer class="text-center text-white">
  
  <div class="container pt-4 wrapper">
    
    <section class="mb-4">
      
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="https://www.facebook.com/profile.php?id=100089795902106&mibextid=LQQJ4d"
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-facebook-f"></i></a>

      
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="https://twitter.com/rabltaojagri?s=09"
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-twitter"></i></a>

     
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="#!"
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-google"></i></a>

      
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="https://instagram.com/rabltaojagri?igshid=MzRlODBiNWFlZA=="
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-instagram"></i></a>

      
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="https://www.linkedin.com/company/rabltaojagri/"
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-linkedin"></i></a>
      
      
    </section>
    
  </div>
  <div className='ftcontent wide'>
    <div>

    </div>
    <div>
       <img src={img22} className='ftlogo'/>
    </div>
    <div className='ftmoto' >
         COMPLETE UMBRELLA FOR FARMERS
    </div>
    <div>

    </div>
  </div>
  
  
  <div class="text-center text-dark p-3">
    © 2023 Copyright:
    <a class="text-dark" href="https://mdbootstrap.com/">RabltoajAgri.in</a>
  </div>
  
</footer>
    

      <div/>  
  </>
   
   
   
   
    
   
  );
}