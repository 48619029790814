import React from 'react';
import './index2.css';
import img8 from "./Images/logggo.png";
import img30 from "./Images/logu.png";
import { Link } from 'react-router-dom/cjs/react-router-dom';


 export default function Loan(){
  
       
    return (
        
        
        <div>
      
        <div>
          <div className='tppor'>
          <img src={img30} className='imo'/>
          
          </div>
        
          
    <div className='hd'>
    Loan Upto 25 Lakhs
      </div> 
      <div className='container'>
      <div className='sd'>
      We fuel your dream to being Agripreneur
        </div> 
        
       <div className='tx'>
       <p>
       "As we expand our franchise network across the country, we recognize that some agripreneurs may face financial constraints when it comes to opening a Kisan Canteen franchise. To address this challenge, we are proud to introduce a groundbreaking solution - we offer loans of up to 25 lakhs to support the complete setup of our Kisan canteen franchises.

       </p>
       <p>
       Our comprehensive loan package covers everything from groceries to agri inputs, basic electronics to highbred plants, and even includes soil testing facilities and access to the latest agricultural technology. 
       </p>
       <p>
       We have designed the loan application process to be straightforward and free from undue pressure, with a strong commitment to offering low-interest rates.
       </p>
       <p>
       With our support, there is no need to worry about funding issues when it comes to opening a Kisan Canteen franchise. We deeply value our franchise partners and strive to provide them with the financial assistance and resources they need to succeed."
       </p>
       </div>
      
        
    </div>
    </div>
    <div className='footr'>
          <div className='qr'>
            <img src={img8} width={300}/>
          </div>
          <div className='more'>
             <div className='link'>
                  More Links
             </div>
             <div className='links'>
             <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><Link to='./Who'>Who We Are</Link></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'><Link to='./About'>Research And Developmentz</Link></div>
              <div className='ptr'>Career</div>
              
              
              
    
             </div>
          </div>
          <div className='cont'>
          <div className='link'>
                  Contact Us
             </div>
             <div className='ptr'>Contact@rabltoajagri.in</div>
          </div>
        </div>
    </div>   
    );
  }