import React from 'react';
import './index2.css';
import img8 from "./Images/night.png";
import img30 from "./Images/logu.png";
import { Link } from 'react-router-dom/cjs/react-router-dom';


 export default function Trade(){
  
       
    return (
        
        
        <div>
      
        <div>
          <div className='tppor'>
          <img src={img30} className='imo'/>
          
          </div>
        
        
    <div className='hd'>
    Agri Trade 
      </div> 
      <div className='container'>
      <div className='sd'>
      Bridging the gap between farmers and markets
        </div> 
       <div className='tx'>
       <p>
       At Kisan canteen, our commitment extends beyond providing basic necessities and technology; we are dedicated to bridging the gap between farmers and markets, regardless of their scale. We're diligently working to turn this vision into a reality, ensuring that farmers, whether small or large, gain access to better prices for their produce.
       </p>
       <p>
       Our efforts involve connecting their agricultural yields directly to corporate buyers and major players in the industry. Furthermore, we are thrilled to announce the impending launch of a flagship initiative that promises to revolutionize the entire agricultural trade chain. Stay tuned, as we will unveil this transformative phase of our business in the presence of the esteemed Sonu Sood.
       </p>
       <p>
       Our motto is crystal clear: Farmers' produce deserves a better market and better prices. Simultaneously, we are actively working to connect farmers' produce to international markets, further expanding their opportunities and contributing to the growth of Indian agriculture on a global scale.
       </p>
       </div>
      
       </div>
    </div>
    <div className='footr'>
          <div className='qr'>
            <img src={img8} width={300}/>
          </div>
          <div className='more'>
             <div className='link'>
                  More Links
             </div>
             <div className='links'>
             <div className='ptr'><Link to='/Grocery'>Grocery</Link></div>
              <div className='ptr'><Link to='/Soil'>Soil Testing</Link></div>
              <div className='ptr'><Link to='/Input'>Agri Input</Link></div>
              <div className='ptr'><Link to='/Finance'>Agri Finance</Link></div>
              <div className='ptr'><Link to='/Advisory'>Advisory</Link></div>
              <div className='ptr'><Link to='/Equip'>Equipments</Link></div>
              <div className='ptr'><Link to='/Farm'>Farm Intelligence</Link></div>
              <div className='ptr'><Link to='/Trade'>Agri Trade</Link></div>
              
              
    
             </div>
          </div>
          <div className='navi'>
          <div className='link'>
                  Navigation
             </div>
             <div className='links'>
              <div className='ptr'><Link to='./Who'>Who We Are</Link></div>
              <div className='ptr'>Being Agripreneur</div>
              <div className='ptr'>Kisan Ki Baat</div>
              <div className='ptr'><Link to='./About'>Research And Developmentz</Link></div>
              <div className='ptr'>Career</div>
              
              
              
    
             </div>
          </div>
          <div className='cont'>
          <div className='link'>
                  Contact Us
             </div>
             <div className='ptr'>Contact@rabltoajagri.in</div>
          </div>
        </div>
    </div>   
    );
  }